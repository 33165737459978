<template>
	<div class="garden-detail-page">
		<div class="form-wrapper">
			<a-form-model :model="form" ref="parkForm" :rules="policyRules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-row>
					<a-col :span="8">
						<a-form-model-item ref label="类型" prop>
							<a-select class="a-select" placeholder="请选择" v-model="form.service_type_id">
								<a-select-option v-for="(item, index) in serveList" :key="item.service_type_id">{{ item.service_type_name }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref="park_name" label="名称" prop="park_name">
							<a-input v-model="form.article_name" placeholder="请输入名称" />
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref label="来源" prop="source">
							<a-input v-model="form.source" placeholder="请输入来源" />
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref label="浏览量" prop="input_count">
							<a-input type="number" v-model="form.input_count" placeholder="请输入" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref label="是否发布" prop>
					<a-switch :checked="form.is_show == 1" @change="showChange" />
				</a-form-model-item>
				<a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="header_img" label="展示图" prop="header_img">
					<a-upload :action="$domain + '/api/resource/uploadImg'" list-type="picture-card" @change="handleHead" :show-upload-list="false">
						<img class="place-img" :src="$imglink + form.header_img" v-if="form.header_img" />
						<div v-else>
							<a-icon type="plus" />
							<div class="ant-upload-text">上传图片</div>
						</div>
					</a-upload>
				</a-form-model-item>
				<a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="park_introduce" label="服务详情" prop="content">
					<div id="editor1-toolbar-container"></div>
					<div id="editor1"></div>
				</a-form-model-item>
			</a-form-model>
			<div class="sub-form-btn-box">
				<a-button :loading="subing" type="primary" @click="subForm">提交</a-button>
			</div>
		</div>
		<div class="loading-mask" v-show="subing">
			<a-spin>
				<a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
			</a-spin>
		</div>
	</div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from "../../utils/uploader";
import { toolbar, fontSize } from '../../utils/toolbar'

export default {
	data() {
		let addrValidator = (rule, value, callback) => {
			console.log(value)
			if (value.length <= 0) {
				callback(new Error('请补全地域信息'));
			} else {
				callback();
			}
		};
		let countValidator = (rule, value, callback) => {
			console.log(value)
			if (value.length != '' && isNaN(value)) {
				callback(new Error('浏览量只能请填写数字'));
			} else {
				callback();
			}
		};
		return {
			editor1: null,
			editor1Data: '',
			subing: false,
			labelCol: { span: 6 },
			wrapperCol: { span: 18 },
			serveList: [],
			id: '',
			form: {
				service_type_id: undefined,
				article_name: '',
				source: '',
				input_count: '',
				is_show: -1,
				header_img: '',
				content: '',
				type: 2
			},
			policyRules: {
				service_type_id: [{ required: true, message: '请选择类型', trigger: 'change' }],
				article_name: [{ required: true, message: '请填写服务名字', trigger: 'blur' }],
				source: [{ required: true, message: '请填写来源', trigger: 'blur' }],
				header_img: [{ required: true, message: '请选择服务展示图片', trigger: 'change' }],
				content: [{ required: true, message: '请输入服务详情', trigger: 'change' }],
				input_count: [{
					validator: countValidator, trigger: 'change'
				}]
			},
		}
	},
	computed: {},
	watch: {
		editor1Data(val) {
			this.form.content = val
		}
	},
	async created() {
		let serRes = await this.getServiceTypeSelect()
		if (serRes.code == 0) {
			console.log(typeof serRes.data[0].service_type_id)
			this.serveList = serRes.data
		}

		if (this.$route.query.id) {
			this.id = this.$route.query.id
			this.getServe()
		}
	},
	mounted() {
		let t = this
		t.initCKEditor('editor1')
	},
	methods: {
		myUploadImagePlugin(ele) {
			let t = this
			t[ele].plugins.get("FileRepository").createUploadAdapter = loader => {
				return new MyUploadAdapter(loader);
			};
			t[ele].model.document.on('change:data', function () {
				t[ele + 'Data'] = t[ele].getData()
			});
		},
		initCKEditor(ele) {
			let t = this
			CKEditor.create(document.querySelector('#' + ele), {
				toolbar: toolbar,
				fontSize: fontSize,
				language: 'zh-cn',
				ckfinder: {
					uploadUrl: '/admin/Upload/uploadUrl'
					//后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
				},
			}).then(editor => {
				const toolbarContainer = document.querySelector('#' + ele + '-toolbar-container');
				console.log(toolbarContainer)
				toolbarContainer.appendChild(editor.ui.view.toolbar.element);
				this[ele] = editor //将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
				t.myUploadImagePlugin(ele)
				console.log(Array.from(this[ele].ui.componentFactory.names()))
			}).catch(error => {
				console.error(error);
			});
		},
		getServe() {
			let t = this
			t.$post('ServiceArticleInfo', { article_id: t.id, type: 2 }).then(res => {
				let { code, data, msg } = res
				if (code == 0) {
					this.form = data
					this.editor1.setData(data.content)
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {

			})
		},
		getServiceTypeSelect() {
			return this.$post('serviceTypeSelect', { type: 2 })
		},
		showChange(e) {
			this.form.is_show = e ? 1 : -1
		},
		handleHead(res) {
			console.log(res);
			if (res.file.status == 'done') {
				this.form.header_img = res.file.response.data.resource_id;
				console.log(this.form.header_img);
			}
		},
		subForm() {
			let t = this

			t.$refs.parkForm.validate(valid => {
				if (valid) {
					t.subing = true;
					let params = JSON.parse(JSON.stringify(this.form))
					let url = t.id ? 'ServiceArticleEdit' : 'ServiceArticleAdd'
					t.$post(url, params).then(res => {
						let { code, data, msg } = res
						t.subing = false
						if (code == 0) {
							t.$message.success(msg, 1.5)
							t.$router.go(-1)
						} else {
							t.$message.error(msg, 1.5)
						}
					}).catch(err => {
						t.subing = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
</style>
	